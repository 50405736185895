
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ChipListVue extends Vue {
  public itemRules !: any
  public editableItems: any = [{}]
  public newItem: string = ''
  public newItemValid: boolean = false
  public dirty: boolean = false

  public $refs!: Vue['$refs'] & {
    newItemForm: any,
  }

  @Prop({ default: () => [] })
  public items!: string[]
  @Prop({ default: 'Add new tags (press space or enter to confirm)' })
  public title!: string
  @Prop({ default: 'Tag' })
  public itemName!: string
  @Prop({ default: 'mdi-bookmark-plus' })
  public itemIcon!: string
  @Prop({ default: () => ([]) })
  public extraRules!: any
  @Prop({ default: false })
  public disabled!: boolean

  protected created() {
    this.itemRules = [
      (v: any) => !!v || 'Must not be empty',
      (v: any) => !this.editableItems.some((tag: any) => tag.value === v) || v + ' already added',
    ]
    this.itemRules.push(...this.extraRules)
    this.Reset()
  }

  public get EditedItems(): string[] {
    return this.editableItems.filter((tag: any) => tag.isOpen).map((x: any) => x.value) as string[]
  }

  public Reset() {
    this.dirty = false
    // Chips must have the value and a boolean property to track if the
    // chip is open or closed (X was clicked).
    let index = 0
    this.editableItems = this.items.map((x: any) => ({
      value: x,
      id: index++,
      isOpen: true,
    }))
  }

  private addItem() {
    if (this.newItem && this.newItemValid) {
      this.dirty = true
      this.editableItems.push(
        {
          value: this.newItem,
          id: this.editableItems.length,
          isOpen: true,
        })
      this.newItem = ''
    }
    this.$refs.newItemForm.reset()
  }

  private closedItem() {
    this.dirty = true
  }

  private isNoItems() {
    return !(this.EditedItems && this.EditedItems.length > 0)
  }

  private onBlur() {
    this.$refs.newItemForm.resetValidation()
  }
}
