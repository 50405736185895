
/* eslint-disable-next-line lines-around-comment */
/**
 * This Vue component is responsible for replacing old vuetify2 v-data-table component.
 *
 * Key features:
 * - 1. Display tabular data, customisable columns via slots.
 * - 2. Can enable row selection (default off): multi-select, select-all, enable/disable row for selection.
 *      Custom sorting function is required. Custom check function can be used to enable/disable certain rows for selection.
 *      Max selection number can be passed to limit how many rows can be selected at once.
 * - 3. Sorting (default off).
 * - 4. Pagination (default on): front end pagination, or emit event to trigger manual pagination (i.e. backend pagination).
 *      If no pagination related Props are passed in, front end pagination will be used by default.
 */

import { DataTableHeading } from '@/modules/library/types'
import { StandardObject } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { DataTableConfig } from '@/utils/data-table-helper'
import { orderBy } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class DataTableVue extends Vue {
  @Prop({ type: String, default: '' })
  public uniqueId!: string
  @Prop({ type: Array as any, default: () => [] })
  public dataRows!: any[]
  @Prop({ type: Object, default: () => ({}) })
  public dataHead!: StandardObject
  @Prop({ type: Array as any, default: () => [50, 100] })
  public itemsPerPageOptions!: number[]
  @Prop({ type: Number, default: 0 })
  public itemsPerPage!: number
  @Prop({ type: Number, default: 1 })
  public currentPage!: number
  @Prop({ type: Number, default: 0 })
  public totalItemsNum!: number
  @Prop({ type: Number, default: 0 })
  public totalPages!: number
  @Prop({ type: Boolean, default: true })
  public enablePagination!: boolean
  @Prop({ type: String, default: '' })
  public sortDefault!: string
  @Prop({ type: Boolean || undefined, default: undefined })
  public dataSelectAll!: boolean | undefined
  @Prop({ type: Boolean || undefined, default: undefined })
  public dataSelect!: boolean | undefined
  @Prop({ type: String, default: '' })
  public dataSelectKey!: string
  @Prop({ type: Function, default: () => false })
  public dataSelectDisableCheck!: (item: any) => boolean
  @Prop({ type: Boolean, default: true })
  public frontEndPagination!: boolean
  @Prop({ type: Number, default: -1 })
  public multiSelectMax!: number

  private items: any[] = []
  private sortBy: string = ''
  private rowsSelected: any[] = []
  private rowsSelectedItems: any[] = []
  private selectAllRows: boolean = false
  private localCurrentPage: number = 1
  // When we declare itemsPerPageNum as below,
  // and initialised as a plain class property,
  // it is not part of Vue's reactivity system.
  // However itemsPerPageNum has been used in other Computed Properties,
  // and Computed Properties are reactive by default in Vue2 design.
  // Class Properties like itemsPerPageNum don't automatically
  // become reactive, even if you update them in a watcher.
  public itemsPerPageNum: number = this.itemsPerPage !== 0 ? this.itemsPerPage : this.itemsPerPageOptions[0]

  // Solution: initialise again as reactive data property
  data() {
    return {
      itemsPerPageNum: this.itemsPerPage !== 0 ? this.itemsPerPage : this.itemsPerPageOptions[0],
    }
  }

  created() {
    this.localCurrentPage = this.currentPage
  }

  @Watch('dataRows', { immediate: true })
  private onDataRowsChanged(newData: any[]) {
    this.items = newData
  }

  @Watch('sortDefault', { immediate: true })
  private onSortDefaultChanged(newVal: string) {
    this.sortBy = newVal
  }

  @Watch('itemsPerPage', { immediate: true })
  private onItemsPerPageChanged(newVal: number) {
    this.itemsPerPageNum = newVal !== 0 ? newVal : this.itemsPerPageOptions[0]
  }

  // Watch for changes in the prop to keep the local state in sync
  @Watch('currentPage')
  onCurrentPageChanged(newVal: number) {
    this.localCurrentPage = newVal
  }

  private get startItemNumber(): number {
    return this.itemsPerPageNum === -1 ? 1 : (this.localCurrentPage - 1) * this.itemsPerPageNum + 1
  }

  private get endItemNumber(): number {
    return this.itemsPerPageNum === -1 ? this.totalItemsVal : Math.min(this.localCurrentPage * this.itemsPerPageNum, this.totalItemsVal)
  }

  private get paginationSummary(): string {
    if (this.totalItemsVal <= 0) {
      return '0/0'
    }

    return `${this.startItemNumber} - ${this.endItemNumber} / ${this.totalItemsVal}`
  }

  private get totalItemsVal(): number {
    return this.totalItemsNum === 0 ? this.items.length : this.totalItemsNum
  }

  private get totalPagesVal(): number {
    if (this.totalItemsNum === 0 || (this.totalPages === 0 && this.itemsPerPage === -1)) {
      // Show all items with no pagination, hence 1 page in total
      return 1
    } else if (this.totalPages === 0) {
      // Calculate total pages
      return Math.ceil(this.items.length / this.itemsPerPage)
    } else {
      // Return passed in totalPages number
      return this.totalPages
    }
  }

  private get dataItems(): any[] {
    return this.items.length < this.startItemNumber ? this.items : this.items.slice(this.startItemNumber - 1, this.endItemNumber)
  }

  private get getQueryParams() {
    let queryOptions: { [key: string]: any } = {}

    if (this.$route.query) {
      queryOptions = {
        ...this.$route.query,
      }
    }
    queryOptions.page = `${this.localCurrentPage}`

    return queryOptions
  }

  private get isMaxItemsSelected(): boolean {
    return this.multiSelectMax >= 0 && this.rowsSelectedItems.length === this.multiSelectMax
  }

  public ResetDefaultSortBy() {
    this.sortBy = this.sortDefault
  }

  public UnselectAllItems() {
    this.selectAllRows = false
    this.rowsSelected = []
    this.rowsSelectedItems = []
  }

  private selectDisabledCheck(data: any) {
    return this.dataSelectDisableCheck(data) || (this.isMaxItemsSelected && !this.rowsSelected.includes(data[this.dataSelectKey]))
  }

  private onItemsPerPageChange() {
    // If new config exceeds total item count, set current page to the last valid page.
    if (this.itemsPerPageNum * this.localCurrentPage > this.totalItemsNum) {
      this.localCurrentPage = Math.ceil(this.totalItemsNum / this.itemsPerPageNum)
    }

    this.$emit('on-items-per-page-change', {
      currentPage: this.localCurrentPage,
      newItemsPerPage: this.itemsPerPageNum,
    })
  }

  private goToPrevPage() {
    if (this.localCurrentPage === 1) {
      return
    }

    this.localCurrentPage -= 1
    if (!this.frontEndPagination) {
      this.UnselectAllItems()

      if (!AppHelper.isDeepEqual(this.$route.query, this.getQueryParams)) {
        this.$router.replace({ query: this.getQueryParams })
      }
    }
    this.$emit('on-go-to-prev-page', this.localCurrentPage)
  }

  private goToNextPage() {
    if (this.localCurrentPage === this.totalPagesVal) {
      return
    }

    this.localCurrentPage += 1
    if (!this.frontEndPagination) {
      this.UnselectAllItems()

      if (!AppHelper.isDeepEqual(this.$route.query, this.getQueryParams)) {
        this.$router.replace({ query: this.getQueryParams })
      }
    }
    this.$emit('on-go-to-next-page', this.localCurrentPage)
  }

  private sortData(heading: DataTableHeading<true>) {
    this.items = orderBy(
      this.items,
      heading.sortFunc || heading.sortBy || this.sortBy,
      heading.isAsc ? DataTableConfig.sortTerm.asc : DataTableConfig.sortTerm.desc
    )

    if (!this.selectAllRows) {
      this.UnselectAllItems()
    }
  }

  private theadEvents(heading: DataTableHeading<true | false>, id: string) {
    const events: any = {}
    let sorted = false

    if (heading.sort) {
      events.click = () => {
        if (this.sortBy === id) {
          heading.isAsc = !heading.isAsc
        } else {
          this.sortBy = id
        }

        if (this.frontEndPagination && !heading.sortByQuery) {
          this.sortData(heading)
          sorted = true
        }

        this.$emit('on-sorting', {
          isSortingAsc: heading.isAsc,
          sortId: id,
          sortByQuery: heading.sortByQuery,
          sorted,
        })
      }
    }

    return events
  }

  private onItemSelectChange() {
    this.rowsSelectedItems = this.dataItems.filter((item: any) => this.rowsSelected.includes(item[this.dataSelectKey]))

    this.$emit('on-item-select-change', {
      rowsSelected: this.rowsSelected,
      rowsSelectedItems: this.rowsSelectedItems,
    })
  }

  private selectAllRowsChanged() {
    if (this.selectAllRows) {
      for (const item of this.dataRows) {
        if (!this.dataSelectDisableCheck(item) && !this.rowsSelected.includes(item[this.dataSelectKey])) {
          this.rowsSelected.push(item[this.dataSelectKey])
          this.rowsSelectedItems.push(item)
        }

        if (this.isMaxItemsSelected) {
          break
        }
      }
    } else {
      this.rowsSelected = []
      this.rowsSelectedItems = []
    }

    this.$emit('on-item-select-all-change', {
      selectAllRows: this.selectAllRows,
      rowsSelected: this.rowsSelected,
      rowsSelectedItems: this.rowsSelectedItems,
    })
  }
}
