
import JigTooltip from '@/components/input/JigTooltip.vue'
import IconBin from '@/components/svg/IconBin.vue'
import IconCopy from '@/components/svg/IconCopy.vue'
import IconMore from '@/components/svg/IconMore.vue'
import IconOutLink from '@/components/svg/IconOutLink.vue'
import IconShare from '@/components/svg/IconShare.vue'
import IconSwitch from '@/components/svg/IconSwitch.vue'
import JigLock from '@/mixin/JigLock'
import JigPermissions from '@/mixin/JigPermissions'
import { JigMetadata, UserActivity } from '@/store/modules/jig/types'
import { StandardObject } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { mixins } from 'vue-class-component'
import { Component, Prop, Watch } from 'vue-property-decorator'

/**
 * This Vue component is responsible for extra Jig actions.
 *
 * Actions includes:
 * - 1. Transfer Jig
 * - 2. Open in browser (deeplink)
 * - 3. Delete Jig
 *
 * Key features:
 * - 1. Proceed relevant Jig action
 * - 2. Disable/enable delete action based on `canDeleteOrEditJig` prop value
 */
@Component({
  components: {
    'icon-bin': IconBin,
    'icon-copy': IconCopy,
    'icon-more': IconMore,
    'icon-out-link': IconOutLink,
    'icon-share': IconShare,
    'icon-switch': IconSwitch,
    'jig-tooltip': JigTooltip,
  },
})
export default class MoreActionsVue extends mixins(JigPermissions, JigLock) {
  @Prop({ type: String, default: '' })
  public customClass!: string
  @Prop({ type: Object, default: {} })
  public jigItem!: JigMetadata
  @Prop({ type: Boolean, default: false })
  public duplicate!: boolean
  @Prop({ type: Boolean, default: false })
  public isCopyable!: boolean
  @Prop({ type: Boolean, default: false })
  public sharelink!: boolean
  @Prop({ type: Boolean, default: false })
  public shareDisabled!: boolean
  // Used to disable actions that are not available for non-editable + non-deletable Jig.
  @Prop({ type: Boolean, default: false })
  public canDeleteOrEditJig!: boolean

  private jig: JigMetadata | StandardObject = {}

  @Watch('jigItem')
  private async onJigItemChange(value: JigMetadata) {
    this.jig = value
  }

  protected mounted() {
    this.jig = this.jigItem
  }

  private get jigDeepLink(): string {
    return this.jig.DeeplinkURL || ''
  }

  // Jig can be transfered only by TeamAdmin or SuperUser and also when Jig is not locked.
  private get canTransferJig(): boolean {
    if (this.jig.OwnerTenantID && (TenantHelpers.IsTeamAdmin(this.currentSignedInUser) || this.isSuperUser) && !this.isJigLockedByOthers) {
      return true
    }

    return false
  }

  private get isJigLockedByOthers(): boolean {
    return this.IsJigLockActive && this.IsJigLockOwnedByOthers(this.jig as JigMetadata)
  }

  private get transferJigTipCopy(): string {
    if (this.isJigLockedByOthers) {
      return `${this.JigLockOwnerName} (${
        this.JigLockUIDDisplay
      }) is currently editing the Jig, so it cannot be transferred. The lock will expire at ${new Date(
        (this.jig.UserActivity as UserActivity).LockExpiry
      ).toLocaleString()}.`
    }

    return 'Only team Admins can transfer Jig'
  }

  private transferJig() {
    this.$emit(this.jigEvents.onJigTransfer, this.jig)
  }

  private deleteJig() {
    this.$emit(this.jigEvents.onJigDelete)
  }

  private duplicateJig() {
    this.$emit(this.jigEvents.onJigDuplicate)
  }

  private copySharelink(event?: Event) {
    this.$emit(this.jigEvents.onJigSharelinkCopy, { event })
  }
}
